import * as React from "react"
import { useEffect } from "react";
import Layout from "../../templates/layout-standard"
import Seo from "../../components/seo"
import SideBar from '../../components/sidebar';
import SpeakWithAdvisor from '../../components/ui/SpeakWithAdvisor';

const AttendASeminar = ({ location }) => {

	useEffect (() => {
		window.MsCrmMkt = null;
		const dynFormScript = document.createElement("script");
		dynFormScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.74.2001.0";
		dynFormScript.async = false;
		document.body.appendChild(dynFormScript);

		const dynTrackScript = document.createElement("script");
		dynTrackScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.76.2003.0";
		dynTrackScript.async = false;
		document.body.appendChild(dynTrackScript);
	},[]);

	return (
		<Layout location={location} heading1="Meeting Options" heading2="Let us guide you through Medicare.">
			<Seo title="Request a Consultation" meta={[{description: 'Get the latest information so you can make confident decisions about your coverage.'}]} bodyclass="meet-with-us subpage" />
			
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">
					
						<main>
							<h3>Request a Consultation</h3>
							<p>UVM Health Advantage Plan Guides are a great resource. So let&rsquo;s work together! Learn the basics of Medicare or explore the plans available to you. Get the latest information so you can make confident decisions about your coverage.</p>
							<p dangerouslySetInnerHTML={{ __html: process.env.GATSBY_REQUEST_A_CONSULT_MS }}></p>

							<SpeakWithAdvisor />
						</main>

						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default AttendASeminar
